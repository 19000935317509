<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'customer-create create-form'"
    v-if="getPermission('customer:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">
        <template v-if="equipmentId > 0"> Update Equipment </template>
        <template v-else> Add New Equipment</template>

        <Barcode
          v-if="unique_barcode"
          class="my-0 mt-1 mb-0 mr-1 ml-2"
          :barcode="unique_barcode"
        >
        </Barcode>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-if="!isCustomer"
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>

      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="assetForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-tabs
          v-model="assetTab"
          background-color="transparent"
          centered
          color="cyan"
          fixed-tabs
          class="custom-tab-transparent"
        >
          <template v-for="(tab, index) in tabs">
            <v-tab
              class="font-size-16 font-weight-600 px-8"
              v-if="
                getPermission(tab.permission + ':create') &&
                tab.status &&
                tab.key == 'purchase-info' &&
                showPurchaseInfoTab &&
                allowPurchaseinfo
              "
              :key="index"
              :href="'#tab-' + tab.key"
            >
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>

            <v-tab
              class="font-size-16 font-weight-600 px-8"
              v-if="
                getPermission(tab.permission + ':create') &&
                tab.status &&
                tab.key != 'purchase-info'
              "
              :key="index"
              :href="'#tab-' + tab.key"
            >
              <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
              {{ tab.title }}
            </v-tab>
          </template>
        </v-tabs>
        <v-tabs-items v-model="assetTab">
          <v-row>
            <v-col class="iv-custom-field" cols="9" md="9" sm="9">
              <v-tab-item :value="'tab-overview'">
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Core Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row v-if="!isCustomer && !equipmentId">
                                    <v-col lg="12" class="d-flex">
                                      <!-- <label
                                        class="font-size-16 font-weight-500"
                                        for="asset_type"
                                        >Asset Type</label
                                      > -->
                                      <v-btn
                                        depressed
                                        tile
                                        :outlined="
                                          !(asset.main_type == 'main_customer')
                                        "
                                        v-on:click="
                                          (asset.main_type = 'main_customer'),
                                            (asset.product = null),
                                            (asset.asset_type = 'personal'),
                                            ChangeGroupRadio('personal')
                                        "
                                        value="main_customer"
                                        color="green darken-3 white--text"
                                        v-on:change="checkType"
                                        >Customer</v-btn
                                      >
                                      <v-btn
                                        class="mx-2"
                                        depressed
                                        v-on:change="checkType"
                                        tile
                                        :outlined="
                                          !(asset.main_type == 'main_company')
                                        "
                                        v-on:click="
                                          (asset.main_type = 'main_company'),
                                            (asset.asset_type = 'company'),
                                            (asset.name = null),
                                            (asset.serial_no = null),
                                            (asset.category = null),
                                            (asset.brand = null),
                                            (asset.warranty_duration_type =
                                              null),
                                            (asset.warranty_duration = null),
                                            ChangeGroupRadio('company')
                                        "
                                        value="main_company"
                                        color="blue darken-4 white--text"
                                        >Company</v-btn
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="!equipmentId">
                                    <v-col lg="12" class="d-flex">
                                      <!-- <label
                                        class="font-size-16 font-weight-500"
                                        for="asset_type"
                                        >Asset Type</label
                                      > -->
                                      <!--:disabled="
                                          asset.main_type == 'main_company'
                                        "-->
                                      <!-- (asset.name = null),
                                            (asset.serial_no = null),
                                            (asset.product = 0)-->
                                      <v-radio-group
                                        v-model="asset.asset_type"
                                        row
                                        hide-details
                                        class="mt-0"
                                        v-on:change="
                                          (asset.name = null),
                                            (asset.serial_no = null),
                                            (asset.product = 0),
                                            ChangeGroupRadio($event)
                                        "
                                      >
                                        <v-radio
                                          style="margin-right: -2px"
                                          value="personal"
                                          color="cyan"
                                          v-if="
                                            asset.main_type == 'main_customer'
                                          "
                                        ></v-radio>
                                        <v-chip
                                          label
                                          color="cyan"
                                          outlined
                                          class="text-white p-3 mr-5"
                                          small
                                          v-if="
                                            asset.main_type == 'main_customer'
                                          "
                                          >Customer Own Equipment</v-chip
                                        >
                                        <v-radio
                                          value="company"
                                          color="red"
                                          style="margin-right: -2px"
                                          v-if="
                                            asset.main_type == 'main_company'
                                          "
                                        ></v-radio>
                                        <!--:disabled="
                                          asset.main_type == 'main_customer'
                                        "-->
                                        <v-chip
                                          label
                                          color="red"
                                          outlined
                                          class="text-white p-3"
                                          small
                                          v-if="
                                            asset.main_type == 'main_company'
                                          "
                                          >A&G Purchased</v-chip
                                        >

                                        <v-radio
                                          value="cross-rent"
                                          color="red"
                                          style="
                                            margin-right: -2px;
                                            margin-left: 10px;
                                          "
                                          v-if="
                                            asset.main_type == 'main_company'
                                          "
                                        ></v-radio>
                                        <v-chip
                                          label
                                          color="red"
                                          outlined
                                          class="text-white p-3"
                                          small
                                          v-if="
                                            asset.main_type == 'main_company'
                                          "
                                          >Cross Rent</v-chip
                                        >
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <template
                                    v-if="asset.main_type == 'main_customer'"
                                  >
                                    <v-row>
                                      <v-col v-if="!isCustomer">
                                        <label
                                          for="customer-equipment"
                                          class="font-weight-700 font-size-16 required"
                                          >Customer</label
                                        >
                                        <v-autocomplete
                                          hide-details
                                          :readonly="
                                            equipmentId > 0 ? true : false
                                          "
                                          id="customer-equipment"
                                          :items="customerList"
                                          dense
                                          flat
                                          filled
                                          label="Customer"
                                          :rules="[
                                            validateRules.required(
                                              asset.customer,
                                              'customer'
                                            ),
                                          ]"
                                          item-color="cyan"
                                          color="cyan"
                                          solo
                                          v-model.trim="asset.customer"
                                          item-text="display_name"
                                          item-value="id"
                                          v-on:change="filter_property($event)"
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title
                                                v-html="'No Customer(s) Found.'"
                                              ></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                              <v-img
                                                :lazy-src="$defaultProfileImage"
                                                :src="
                                                  $assetAPIURL(
                                                    item.profile_logo
                                                  )
                                                "
                                                aspect-ratio="1"
                                                class="margin-auto"
                                                transition="fade-transition"
                                              ></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                class="text-capitalize font-weight-500 font-size-16"
                                                >{{
                                                  item.display_name
                                                }}</v-list-item-title
                                              >
                                              <v-list-item-title
                                                class="text-capitalize font-weight-500 font-size-16"
                                                >{{
                                                  item.company_name
                                                }}</v-list-item-title
                                              >
                                            </v-list-item-content>
                                            <v-list-item-action
                                              v-if="item.default_person"
                                              class="align-self-center"
                                            >
                                              <v-list-item-subtitle
                                                class="text-lowercase pb-2 font-weight-500 font-size-14"
                                                >{{
                                                  item.default_person
                                                    .primary_email
                                                }}</v-list-item-subtitle
                                              >
                                              <v-list-item-subtitle
                                                class="text-lowercase font-weight-500 font-size-14"
                                                >{{
                                                  item.default_person
                                                    .primary_phone
                                                }}</v-list-item-subtitle
                                              >
                                            </v-list-item-action>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>

                                      <v-col :lg="isCustomer ? 12 : 6">
                                        <label
                                          for="property-equipment"
                                          class="font-weight-700 font-size-16 required"
                                          >Site Location</label
                                        >
                                        <v-autocomplete
                                          hide-details
                                          id="property-equipment"
                                          :readonly="
                                            equipmentId > 0 ? true : false
                                          "
                                          :items="filtered_property"
                                          :rules="[
                                            validateRules.required(
                                              asset.property,
                                              'property'
                                            ),
                                          ]"
                                          v-model.trim="asset.property"
                                          dense
                                          flat
                                          filled
                                          label="Site Location"
                                          item-color="cyan"
                                          color="cyan"
                                          solo
                                          item-text="barcode"
                                          item-value="id"
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title
                                                v-html="
                                                  'No Site Location(s) Found.'
                                                "
                                              ></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-icon class="mr-3">
                                              <v-icon large
                                                >mdi-home-map-marker</v-icon
                                              >
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                              <v-layout
                                                style="max-width: 150px"
                                              >
                                                <v-flex>
                                                  <Barcode
                                                    class="my-0 mt-0 mb-0 mr-1"
                                                    :barcode="item.barcode"
                                                  ></Barcode>
                                                </v-flex>
                                                <v-flex v-if="item.is_tenant">
                                                  <v-chip
                                                    class="mx-1"
                                                    label
                                                    small
                                                    color="green"
                                                    outlined
                                                    >Tenant</v-chip
                                                  >
                                                </v-flex>
                                                <v-flex>
                                                  <v-chip
                                                    label
                                                    small
                                                    class="ml-1"
                                                    :color="
                                                      item.type == 1
                                                        ? 'cyan'
                                                        : 'orange'
                                                    "
                                                    outlined
                                                  >
                                                    <template
                                                      v-if="item.type == 1"
                                                      >Site Location</template
                                                    >
                                                    <template v-else
                                                      >Billing</template
                                                    >
                                                  </v-chip>
                                                </v-flex>
                                              </v-layout>
                                              <v-list-item-title
                                                class="text-capitalize font-weight-500 font-size-16"
                                                >{{
                                                  get_formatted_property(item)
                                                }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>

                                    <v-row>
                                      <v-col
                                        lg="12"
                                        v-if="asset.asset_type == 'company'"
                                      >
                                        <label
                                          for="customer-equipment"
                                          class="font-weight-700 font-size-16 required"
                                          >Product</label
                                        >
                                        <v-autocomplete
                                          hide-details
                                          id="customer-equipment"
                                          :items="products"
                                          v-model.trim="asset.product"
                                          dense
                                          flat
                                          filled
                                          label="Product"
                                          item-color="cyan"
                                          color="cyan"
                                          solo
                                          item-text="barcode"
                                          :rules="[
                                            validateRules.required(
                                              asset.product,
                                              'product'
                                            ),
                                          ]"
                                          v-on:change="productData($event)"
                                          item-value="id"
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-title
                                                v-html="'No Product(s) Found.'"
                                              ></v-list-item-title>
                                            </v-list-item>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <v-list-item-avatar>
                                              <v-img
                                                :lazy-src="$defaultImage"
                                                :src="
                                                  item &&
                                                  item.product_image &&
                                                  item.product_image.file &&
                                                  item.product_image.file.url
                                                "
                                                aspect-ratio="1"
                                                class="margin-auto"
                                                transition="fade-transition"
                                              ></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                class="text-capitalize font-weight-500 font-size-16"
                                                >{{
                                                  item.name
                                                }}</v-list-item-title
                                              >
                                              <v-list-item-title
                                                class="text-capitalize font-weight-500 font-size-16"
                                                >{{
                                                  item.barcode
                                                }}</v-list-item-title
                                              >
                                            </v-list-item-content>
                                          </template>
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row>
                                  </template>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Installation Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Installation Date"
                                        v-model="asset.installation_date"
                                        :min-date="minDate"
                                        :default-date="asset.installation_date"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Activation Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Activation Date"
                                        v-model="asset.activation_date"
                                        :min-date="minDate"
                                        :default-date="asset.activation_date"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                                        >Name</label
                                      >
                                      <v-text-field
                                        id="name"
                                        v-model.trim="asset.name"
                                        :rules="[
                                          validateRules.required(
                                            asset.name,
                                            'name'
                                          ),
                                        ]"
                                        dense
                                        filled
                                        label="Name"
                                        solo
                                        flat
                                        color="cyan"
                                        class="width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                                        >Serial No.</label
                                      >
                                      <v-text-field
                                        id="Serial No."
                                        :readonly="
                                          asset.asset_type == 'company' &&
                                          asset.main_type != 'main_company'
                                            ? true
                                            : false
                                        "
                                        v-model.trim="asset.serial_no"
                                        :rules="[
                                          validateRules.required(
                                            asset.serial_no,
                                            'Serial No.'
                                          ),
                                        ]"
                                        dense
                                        filled
                                        label="Serial No."
                                        solo
                                        flat
                                        color="cyan"
                                        class="width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Model No</label
                                      >
                                      <v-text-field
                                        id="batch_number"
                                        v-model.trim="asset.batch_number"
                                        dense
                                        filled
                                        label="Model No"
                                        solo
                                        flat
                                        color="cyan"
                                        class="width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Selling Price (SGD)</label
                                      >
                                      <!-- <v-text-field
                                        id="Selling-Price"
                                        type="number"
                                        v-model.trim="asset.selling_price"
                                        dense
                                        filled
                                        label="Selling Price"
                                        solo
                                        flat
                                        color="cyan"
                                        class="width-100"
                                      ></v-text-field> -->
                                      <v-text-field
                                        :min="0"
                                        oninput="if(this.value < 0) this.value = 0;"
                                        type="number"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        placeholder="Selling-Price"
                                        hide-spin-buttons
                                        prepend-inner-icon="mdi-currency-usd"
                                        v-model="asset.selling_price"
                                        class="mt-0"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Category</label
                                      >
                                      <div class="d-flex">
                                        <v-autocomplete
                                          dense
                                          filled
                                          color="cyan"
                                          item-color="cyan"
                                          :items="categoryList"
                                          label="Category"
                                          solo
                                          flat
                                          append-outer-icon="mdi-cog"
                                          v-on:click:append-outer="
                                            manageGroupDialog = true
                                          "
                                          item-text="text"
                                          item-value="text"
                                          class="pr-2 width-100"
                                          v-model.trim="asset.category"
                                        >
                                          <template v-slot:no-data>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  v-html="
                                                    'No Category(s) Found.'
                                                  "
                                                ></v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </template>
                                        </v-autocomplete>
                                      </div>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >End Life</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="End Life"
                                        v-model="asset.end_life"
                                        :min-date="minDate"
                                        :default-date="asset.end_life"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Contact for Item</label
                                      >

                                      <PhoneTextField
                                        v-model="asset.company_number"
                                      ></PhoneTextField>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                      >
                                        Ref No.</label
                                      >
                                      <v-text-field
                                        id="model-equipment"
                                        dense
                                        filled
                                        label="Ref No."
                                        solo
                                        v-model="asset.model"
                                        flat
                                        color="cyan"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Location
                                      </label>

                                      <v-autocomplete
                                        hide-details
                                        id="location-equipment"
                                        :items="equipment_locations"
                                        dense
                                        v-model="asset.location"
                                        flat
                                        filled
                                        label="Location"
                                        item-color="cyan"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="id"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_location = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Location(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Brand</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="brand-equipment"
                                        :items="brands"
                                        dense
                                        flat
                                        filled
                                        label="Brand"
                                        item-color="cyan"
                                        v-model="asset.brand"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_brand = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Brand(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Machine Type
                                      </label>

                                      <v-autocomplete
                                        hide-details
                                        id="machine_types"
                                        :items="machine_types"
                                        dense
                                        v-model="asset.machine_type"
                                        flat
                                        filled
                                        label="Machine Type"
                                        item-color="cyan"
                                        color="cyan"
                                        solo
                                        item-text="name"
                                        item-value="id"
                                        v-on:change="filterModels($event)"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Machine Type(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Machine Model</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="model"
                                        :items="machine_models"
                                        dense
                                        flat
                                        filled
                                        label="Model"
                                        item-color="cyan"
                                        v-model="asset.machine_model"
                                        color="cyan"
                                        solo
                                        item-text="name"
                                        item-value="id"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Models(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Tags</label
                                      >
                                      <TagAutoComplete
                                        type="equipment"
                                        v-model="asset.tags"
                                      />
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Description</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.description"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Description"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <!--  <v-col lg="3" class="d-flex">
                                      <label>Is Return After End Life ? </label>
                                      <v-switch
                                        class="ml-4"
                                        color="cyan"
                                        dense
                                        inset
                                        hide-details
                                        v-model="asset.is_return"
                                      ></v-switch>
                                    </v-col> -->
                                    <v-col lg="3" class="d-flex" v-if="false">
                                      <label>Is any Warranty ? </label>
                                      <v-switch
                                        class="ml-4"
                                        color="cyan"
                                        dense
                                        :readonly="
                                          equipmentId > 0 ? true : false
                                        "
                                        inset
                                        hide-details
                                        v-on:change="changeWarrantyCheck"
                                        v-model="asset.check_warranty"
                                      ></v-switch>
                                    </v-col>
                                    <!-- <v-col
                                      lg="3"
                                      class="d-flex"
                                      v-if="asset.asset_type == 'company'"
                                    >
                                      <label>Is any Purchased Info ? </label>
                                      <v-switch
                                        class="ml-4"
                                        color="cyan"
                                        :readonly="
                                          equipmentId > 0 ? true : false
                                        "
                                        dense
                                        inset
                                        hide-details
                                        v-model="asset.is_purchase_info"
                                      ></v-switch>
                                    </v-col> -->

                                    <!-- <v-col lg="12" class="d-flex">
                                      <label>Is there any Preventive Maintenance ? </label>
                                      <v-switch
                                        class="ml-4"
                                        color="cyan"
                                        dense
                                        :readonly="
                                          equipmentId > 0 ? true : false
                                        "
                                        inset
                                        hide-details
                                        v-on:change="changeMaintananceCheck"
                                        v-model="asset.check_maintanance"
                                      ></v-switch>
                                    </v-col> -->
                                  </v-row>
                                </v-card-text>
                              </v-card>

                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius mt-3"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Documents
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="12">
                                      <div class="ml-1">
                                        Allowed Extensions:
                                        doc,docx,txt,text,rtf,xsl,jpeg,png,gif,zip,rar,pdf,xlsx,word,jpg
                                        <br />
                                        Max files: 5, Max Size: 5MB
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12" class="d-flex">
                                      <table
                                        width="100%"
                                        style="table-layout: fixed"
                                        class="my-auto py-0 align-items-center"
                                        v-if="
                                          wtz_attachments &&
                                          wtz_attachments.length > 0
                                        "
                                      >
                                        <tr
                                          v-for="(
                                            file, index
                                          ) in wtz_attachments"
                                          :key="`create-visit-attachment-${index}`"
                                        >
                                          <td class="">
                                            <v-file-input
                                              hide-details
                                              class="px-0"
                                              dense
                                              filled
                                              solo
                                              flat
                                              v-model="file.file"
                                              placeholder="Select File"
                                              prepend-icon=""
                                              prepend-inner-icon="$file"
                                              v-on:change="
                                                updateFileEquipment(
                                                  index,
                                                  $event
                                                )
                                              "
                                              v-on:click:clear="
                                                updateFileEquipment(
                                                  index,
                                                  $event
                                                )
                                              "
                                              color="cyan"
                                            />
                                          </td>
                                          <td class="py-0">
                                            <v-text-field
                                              hide-details
                                              class="px-0"
                                              v-model="file.name"
                                              placeholder="Name"
                                              dense
                                              filled
                                              solo
                                              flat
                                              color="cyan"
                                            />
                                          </td>
                                          <td
                                            class="px-0 py-0"
                                            width="5%"
                                            align="center"
                                          >
                                            <v-btn
                                              :disabled="
                                                wtz_attachments.length < 2
                                              "
                                              v-on:click="
                                                removeFileEquipment(index)
                                              "
                                              icon
                                              class="w-100"
                                              color="red lighten-1"
                                            >
                                              <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            wtz_attachments &&
                                            wtz_attachments.length < 5
                                          "
                                        >
                                          <td class="" colspan="3">
                                            <v-btn
                                              color="cyan white--text"
                                              class="custom-bold-button"
                                              depressed
                                              v-on:click="addMoreEquipment()"
                                              tile
                                              >Add More</v-btn
                                            >
                                          </td>
                                        </tr>
                                      </table>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>

                              <v-card
                                v-if="
                                  asset.is_purchase_info &&
                                  asset.asset_type == 'company'
                                "
                                flat
                                class="custom-grey-border remove-border-radius mt-4"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Purchased Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Cost Price</label
                                      >
                                      <v-text-field
                                        type="number"
                                        id="cost_price"
                                        v-model.trim="asset.cost_price"
                                        dense
                                        filled
                                        label="Cost Price"
                                        solo
                                        flat
                                        color="cyan"
                                        class="width-100"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Purchased On</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Purchased On"
                                        v-model="asset.purchased_on"
                                        :default-date="asset.purchased_on"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Supplier</label
                                      >
                                      <v-autocomplete
                                        :items="supplierList"
                                        dense
                                        filled
                                        color="cyan"
                                        item-color="cyan"
                                        label="Supplier"
                                        solo
                                        flat
                                        item-text="display_name"
                                        item-value="id"
                                        class="pl-2 width-100"
                                        v-model.trim="asset.vendor"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-content>
                                              <v-list-item-title
                                                v-html="'No Supplier(s) Found.'"
                                              ></v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <!--  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Description</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.description"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Asset Remark"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                  <v-col md3>
                    <template>
                      <v-img
                        :lazy-src="$defaultImage"
                        :src="getProfileImage"
                        max-height="200"
                        max-width="200"
                        height="200px"
                        width="200px"
                        class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                      >
                      </v-img>
                      <div class="margin-auto">
                        <v-btn
                          class="my-4 custom-bold-button width-100"
                          outlined
                          small
                          v-on:click="selectImage"
                          color="cyan"
                          :loading="imageLoading"
                          :disabled="imageLoading"
                        >
                          <template v-if="getProfileImage"
                            >Change Image</template
                          >
                          <template v-else>Upload Image</template>
                        </v-btn>

                        <div class="d-none">
                          <v-file-input
                            ref="uploadImage"
                            v-on:change="updateProfileImage"
                            :rules="imageRules"
                            accept="image/png, image/jpeg, image/jpg"
                          ></v-file-input>
                        </div>
                      </div>
                    </template>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item :value="'tab-allocate-details'"> </v-tab-item>

              <v-tab-item
                :value="'tab-warranty'"
                v-if="assetTab == 'tab-warranty'"
              >
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Warranty Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        for="asset_type"
                                        >Warranty Duration
                                      </label>
                                      <v-text-field
                                        type="number"
                                        id="duration"
                                        v-model.trim="asset.warranty_duration"
                                        dense
                                        filled
                                        label="Duration"
                                        solo
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                        v-on:keyup="
                                          (e) =>
                                            updateWarrantyDuration(
                                              e,
                                              'warranty_duration'
                                            )
                                        "
                                        v-mask="'###'"
                                        :min="0"
                                        oninput="if(this.value < 0) this.value = 0;"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        for="asset_type"
                                        >Warranty Duration Type</label
                                      >
                                      <v-autocomplete
                                        :items="durationTypeList"
                                        dense
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                        filled
                                        color="cyan"
                                        item-color="cyan"
                                        label="Duration Type"
                                        solo
                                        v-on:change="
                                          (e) =>
                                            updateWarrantyDuration(
                                              e,
                                              'warranty_duration_type'
                                            )
                                        "
                                        flat
                                        item-text="text"
                                        item-value="value"
                                        class="pl-2 width-100"
                                        v-model.trim="
                                          asset.warranty_duration_type
                                        "
                                      >
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Start Date
                                      </label>
                                      <DatePicker
                                        solo
                                        :disabled="dateDisable()"
                                        flat
                                        :rules="[
                                          validateRules.required(
                                            asset.warranty_start_date,
                                            'warranty_start_date'
                                          ),
                                        ]"
                                        :nullable="true"
                                        placeholder="Start Date"
                                        v-model="asset.warranty_start_date"
                                        :default-date="
                                          asset.warranty_start_date
                                        "
                                        v-on:change="
                                          (e) =>
                                            updateWarrantyDuration(
                                              e,
                                              'warranty_start_date'
                                            )
                                        "
                                        :offWeekend="false"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :disabled="dateDisable()"
                                        :rules="[
                                          validateRules.required(
                                            asset.warranty_end_date,
                                            'warranty_end_date'
                                          ),
                                        ]"
                                        :nullable="true"
                                        placeholder="End Date"
                                        v-model="asset.warranty_end_date"
                                        :default-date="asset.warranty_end_date"
                                        :min-date="asset.warranty_start_date"
                                        v-on:change="
                                          (e) =>
                                            updateWarrantyDuration(
                                              e,
                                              'warranty_end_date'
                                            )
                                        "
                                        :offWeekend="false"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Cost</label
                                      >
                                      <v-text-field
                                        type="number"
                                        v-model.trim="asset.warranty_cost"
                                        dense
                                        filled
                                        label="Cost"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                        :min="0"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <template
                                    v-if="
                                      asset &&
                                      asset.warranty_reminder &&
                                      asset.warranty_reminder.length > 0 &&
                                      false
                                    "
                                  >
                                    <template
                                      v-for="(
                                        data, index
                                      ) in asset.warranty_reminder"
                                    >
                                      <v-row
                                        :key="`file-row-${index}`"
                                        :class="{
                                          'table-alternate-row': index % 2,
                                        }"
                                      >
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                            >Reminder</label
                                          >
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md4>
                                                  <v-radio-group
                                                    hide-details
                                                    id="export-type"
                                                    class="pt-0"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'warranty'
                                                      )
                                                    "
                                                    v-model="data.type"
                                                    row
                                                  >
                                                    <v-radio
                                                      label="After"
                                                      color="cyan"
                                                      value="after"
                                                    ></v-radio>
                                                    <v-radio
                                                      label="Before"
                                                      color="cyan"
                                                      value="before"
                                                    ></v-radio>
                                                  </v-radio-group>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-text-field
                                                    id="duration"
                                                    v-model.trim="data.duration"
                                                    dense
                                                    filled
                                                    label="Duration"
                                                    solo
                                                    flat
                                                    color="cyan"
                                                    class="pr-2 width-100"
                                                    v-on:keyup="
                                                      manageReminder(
                                                        index,
                                                        'warranty'
                                                      )
                                                    "
                                                  ></v-text-field>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-autocomplete
                                                    :items="durationTypeList"
                                                    dense
                                                    filled
                                                    color="cyan"
                                                    item-color="cyan"
                                                    label="Duration Type"
                                                    solo
                                                    flat
                                                    item-text="text"
                                                    item-value="text"
                                                    class="pl-2 width-100"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'warranty'
                                                      )
                                                    "
                                                    v-model.trim="
                                                      data.duration_type
                                                    "
                                                  >
                                                  </v-autocomplete>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.warranty_reminder
                                                    .length < 2
                                                "
                                                v-on:click="
                                                  removeWarrantyReminder(index)
                                                "
                                                class="white--text"
                                                depressed
                                                color="red darken-4"
                                                tile
                                                style="margin-left: -10px"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.warranty_reminder
                                                    .length > 4 ||
                                                  equipmentId > 0
                                                "
                                                v-on:click="
                                                  addMoreWarrantyReminder()
                                                "
                                                class="white--text"
                                                depressed
                                                color="cyan"
                                                tile
                                                style="margin-left: -13px"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <template
                                    v-if="
                                      wtx_attachments &&
                                      wtx_attachments.length > 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        file, indexFile
                                      ) in wtx_attachments"
                                    >
                                      <v-row
                                        :key="`warranty-file-row-${indexFile}`"
                                        :class="{
                                          'table-alternate-row': indexFile % 2,
                                        }"
                                      >
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                            >Attachment</label
                                          >
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md6>
                                                  <v-file-input
                                                    :id="`document-file-${indexFile}`"
                                                    placeholder="Select File"
                                                    class="removeShadow"
                                                    prepend-icon=""
                                                    dense
                                                    solo
                                                    prepend-inner-icon="mdi-attachment"
                                                    hide-details
                                                    v-model="file.file"
                                                    v-on:change="
                                                      updateFile(
                                                        indexFile,
                                                        $event
                                                      )
                                                    "
                                                    v-on:click:clear="
                                                      updateFile(
                                                        indexFile,
                                                        $event
                                                      )
                                                    "
                                                  ></v-file-input>
                                                </v-flex>
                                                <v-flex md6>
                                                  <v-text-field
                                                    :id="`document-name-${indexFile}`"
                                                    v-model="file.name"
                                                    dense
                                                    filled
                                                    placeholder="File Name"
                                                    solo
                                                    flat
                                                    color="cyan"
                                                    class="pr-2 width-100"
                                                  ></v-text-field>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  wtx_attachments.length < 2
                                                "
                                                v-on:click="
                                                  removeFileWarranty(indexFile)
                                                "
                                                class="white--text"
                                                depressed
                                                color="red darken-4"
                                                tile
                                                style="margin-left: -10px"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  wtx_attachments.length > 4
                                                "
                                                v-on:click="addMoreWarranty()"
                                                class="white--text"
                                                depressed
                                                color="cyan"
                                                tile
                                                style="margin-left: -13px"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template>
                                  <!--  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Remarks</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.warranty_remarks"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Asset Remark"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.description
                                            ? asset.description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item
                :value="'tab-preventive-maintanance'"
                v-if="assetTab == 'tab-preventive-maintanance'"
              >
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Maintenance Information
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Duration</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Duration"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.preventive_duration"
                                        v-mask="'###'"
                                        v-on:keyup="
                                          (e) =>
                                            updatePreventiveDuration(
                                              e,
                                              'warranty_duration'
                                            )
                                        "
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Duration Type</label
                                      >
                                      <v-select
                                        :items="warrantyDurationTypes"
                                        dense
                                        filled
                                        v-model.trim="
                                          asset.preventive_duration_type
                                        "
                                        solo
                                        flat
                                        class="pl-2 pr-2 width-100"
                                        label="Duration Type"
                                        color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        item-color="cyan"
                                        v-on:change="
                                          (e) =>
                                            updatePreventiveDuration(
                                              e,
                                              'warranty_duration_type'
                                            )
                                        "
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Start Date
                                      </label>
                                      <DatePicker
                                        solo
                                        flat
                                        :nullable="true"
                                        placeholder="Start Date"
                                        v-model="asset.preventive_start_date"
                                        :default-date="
                                          asset.preventive_start_date
                                        "
                                        :disabled="dateDisable()"
                                        :rules="[
                                          validateRules.required(
                                            asset.preventive_start_date,
                                            'preventive_start_date'
                                          ),
                                        ]"
                                        v-on:change="
                                          (e) =>
                                            updatePreventiveDuration(
                                              e,
                                              'preventive_start_date'
                                            )
                                        "
                                        :offWeekend="false"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :nullable="true"
                                        placeholder="End Date"
                                        v-model="asset.preventive_end_date"
                                        :default-date="
                                          asset.preventive_end_date
                                        "
                                        :min-date="asset.preventive_start_date"
                                        :disabled="dateDisable()"
                                        :rules="[
                                          validateRules.required(
                                            asset.preventive_end_date,
                                            'preventive_end_date'
                                          ),
                                        ]"
                                        v-on:change="
                                          (e) =>
                                            updatePreventiveDuration(
                                              e,
                                              'preventive_end_date'
                                            )
                                        "
                                        :offWeekend="false"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Maintenance Cost</label
                                      >
                                      <v-text-field
                                        type="number"
                                        id="cost"
                                        v-model.trim="asset.preventive_cost"
                                        dense
                                        filled
                                        label="Cost"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                        :min="0"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <!-- <template
                                    v-if="
                                      asset &&
                                      asset.maintenance_reminder &&
                                      asset.maintenance_reminder.length > 0
                                    "
                                  >
                                    <template
                                      v-for="(
                                        data, index
                                      ) in asset.maintenance_reminder"
                                    >
                                      <v-row
                                        :key="`file-row-${index}`"
                                        :class="{
                                          'table-alternate-row': index % 2,
                                        }"
                                      >
                                        <v-col md="12" class="py-0">
                                          <label
                                            class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                            >Reminder {{ index + 1 }}</label
                                          >
                                        </v-col>
                                        <v-col md="10" class="py-0">
                                          <v-row>
                                            <v-col md="12" class="py-0">
                                              <v-layout>
                                                <v-flex md4>
                                                  <v-radio-group
                                                    hide-details
                                                    id="export-type"
                                                    class="pt-0"
                                                    v-model="data.type"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                    row
                                                  >
                                                    <v-radio
                                                      label="After"
                                                      :value="0"
                                                      color="cyan"
                                                    ></v-radio>
                                                    <v-radio
                                                      label="Before"
                                                      :value="1"
                                                      color="cyan"
                                                    ></v-radio>
                                                  </v-radio-group>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-text-field
                                                    id="duration"
                                                    v-model.trim="data.duration"
                                                    dense
                                                    filled
                                                    label="Duration"
                                                    solo
                                                    flat
                                                    color="cyan"
                                                    class="pr-2 width-100"
                                                    v-on:keyup="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                  ></v-text-field>
                                                </v-flex>
                                                <v-flex md4>
                                                  <v-autocomplete
                                                    :items="durationTypeList"
                                                    dense
                                                    filled
                                                    color="cyan"
                                                    item-color="cyan"
                                                    label="Duration Type"
                                                    solo
                                                    flat
                                                    item-text="text"
                                                    item-value="text"
                                                    class="pl-2 width-100"
                                                    v-on:change="
                                                      manageReminder(
                                                        index,
                                                        'maintenance'
                                                      )
                                                    "
                                                    v-model.trim="
                                                      data.duration_type
                                                    "
                                                  >
                                                  </v-autocomplete>
                                                </v-flex>
                                              </v-layout>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                        <v-col md="2" class="my-auto py-0">
                                          <v-row>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.maintenance_reminder
                                                    .length < 2
                                                "
                                                v-on:click="
                                                  removeMaintenanceReminder(
                                                    index
                                                  )
                                                "
                                                class="white--text"
                                                depressed
                                                color="red darken-4"
                                                tile
                                                style="margin-left: -10px"
                                              >
                                                <v-icon>mdi-delete</v-icon>
                                              </v-btn>
                                            </v-col>
                                            <v-col md="6" class="py-0">
                                              <v-btn
                                                :disabled="
                                                  asset.maintenance_reminder
                                                    .length > 4
                                                "
                                                v-on:click="
                                                  addMoreMaintenanceReminder()
                                                "
                                                class="white--text"
                                                depressed
                                                color="cyan"
                                                tile
                                                style="margin-left: -13px"
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </template> -->

                                  <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Description</label
                                      >
                                      <v-textarea
                                        v-model.trim="
                                          asset.preventive_description
                                        "
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Description"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.preventive_description
                                            ? asset.preventive_description
                                                .length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row>
                                  <!-- maintenance attachments -->
                                  <v-row>
                                    <v-col lg="12">
                                      <template
                                        v-if="
                                          mtx_attachments &&
                                          mtx_attachments.length > 0
                                        "
                                      >
                                        <template
                                          v-for="(
                                            file, indexFile
                                          ) in mtx_attachments"
                                        >
                                          <v-row
                                            :key="`maintanance-file-row-${indexFile}`"
                                            :class="{
                                              'table-alternate-row':
                                                indexFile % 2,
                                            }"
                                          >
                                            <v-col md="12" class="py-0">
                                              <label
                                                class="pr-2 pl-2 mt-3 font-weight-700 font-size-16 width-100"
                                                >Attachment</label
                                              >
                                            </v-col>
                                            <v-col md="10" class="py-0">
                                              <v-row>
                                                <v-col md="12" class="py-0">
                                                  <v-layout>
                                                    <v-flex md6>
                                                      <v-file-input
                                                        :id="`maintanance-document-file-${indexFile}`"
                                                        placeholder="Select File"
                                                        class="removeShadow"
                                                        prepend-icon=""
                                                        dense
                                                        solo
                                                        prepend-inner-icon="mdi-attachment"
                                                        hide-details
                                                        v-model="file.file"
                                                        v-on:change="
                                                          updateFileMaintanance(
                                                            indexFile,
                                                            $event
                                                          )
                                                        "
                                                        v-on:click:clear="
                                                          updateFileMaintanance(
                                                            indexFile,
                                                            $event
                                                          )
                                                        "
                                                      ></v-file-input>
                                                    </v-flex>
                                                    <v-flex md6>
                                                      <v-text-field
                                                        :id="`document-name-${indexFile}`"
                                                        v-model="file.name"
                                                        dense
                                                        filled
                                                        placeholder="File Name"
                                                        solo
                                                        flat
                                                        color="cyan"
                                                        class="pr-2 width-100"
                                                      ></v-text-field>
                                                    </v-flex>
                                                  </v-layout>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <v-col md="2" class="my-auto py-0">
                                              <v-row>
                                                <v-col md="6" class="py-0">
                                                  <v-btn
                                                    :disabled="
                                                      mtx_attachments.length < 2
                                                    "
                                                    v-on:click="
                                                      removeFileMaintanance(
                                                        indexFile
                                                      )
                                                    "
                                                    class="white--text"
                                                    depressed
                                                    color="red darken-4"
                                                    tile
                                                    style="margin-left: -10px"
                                                  >
                                                    <v-icon>mdi-delete</v-icon>
                                                  </v-btn>
                                                </v-col>
                                                <v-col md="6" class="py-0">
                                                  <v-btn
                                                    :disabled="
                                                      mtx_attachments.length >
                                                        4 || equipmentId > 0
                                                    "
                                                    v-on:click="
                                                      addMoreMaintanance()
                                                    "
                                                    class="white--text"
                                                    depressed
                                                    color="cyan"
                                                    tile
                                                    style="margin-left: -13px"
                                                  >
                                                    <v-icon>mdi-plus</v-icon>
                                                  </v-btn>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </template>
                                      </template>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <v-tab-item
                :value="'tab-extended-info'"
                v-if="assetTab == 'tab-extended-info'"
              >
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    EXTENDED INFORMATION
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Asset Type</label
                                      >

                                      <v-autocomplete
                                        hide-details
                                        id="cats-equipment"
                                        :items="assetCats"
                                        dense
                                        flat
                                        filled
                                        label="Type"
                                        item-color="cyan"
                                        v-model="asset.asset_category"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_asset_category = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Type(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Owner Ref. / Machine No.</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Owner Ref. / Machine No."
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.owner_ref"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Year
                                      </label>
                                      <v-autocomplete
                                        hide-details
                                        id="year-equipment"
                                        :items="years"
                                        dense
                                        flat
                                        filled
                                        label="Year"
                                        item-color="cyan"
                                        v-model="asset.year"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                      >
                                        <!-- append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_asset_category = true
                                        "-->
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Year(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Platform Height</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Platform Height"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.plateform_height"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                      >
                                        Engine Type
                                      </label>

                                      <v-autocomplete
                                        hide-details
                                        id="engine-equipment"
                                        :items="engineTypes"
                                        dense
                                        flat
                                        filled
                                        label="Engine Type"
                                        item-color="cyan"
                                        v-model="asset.engine_type"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_engine_types = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Engine Type(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Original fork c/w</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.originial_fork"
                                        dense
                                        filled
                                        label="Original fork c/w"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Capacity</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.capacity"
                                        dense
                                        filled
                                        label="Capacity"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Chassis num</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.chassis_number"
                                        dense
                                        filled
                                        label="Chassis num"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Engine Number</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.engine_number"
                                        dense
                                        filled
                                        label="Engine Number"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >LTA plate no.</label
                                      >
                                      <v-text-field
                                        id="lta_plate_no"
                                        v-model.trim="asset.lta_plate_no"
                                        dense
                                        filled
                                        label="LTA plate no."
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >NEA plate no.</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.nea_plate_no"
                                        dense
                                        filled
                                        label="NEA plate no."
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >NOA</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.noa"
                                        dense
                                        filled
                                        label="Notice Of Arrival"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >LP / LM Cert</label
                                      >
                                      <v-text-field
                                        filled
                                        label="LP/LM Cert"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.lp_cert"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Code</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Code"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.code"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >LP Cert Inspection Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="LP Cert Inspection Date"
                                        v-model="asset.equip_start_date"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >LP Cert Expiry Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="LP Cert Expiry Date"
                                        v-model="asset.equip_end_date"
                                        :min-date="asset.equip_start_date"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Machinery All Risk</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Machinery All Risk"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.machinery_all_risk"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Hour Meter</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Hour Meter"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.hour_meter"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Breakdown Log</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Breakdown Log"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.breakdown_log"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <!-- <v-row>
                                    <v-col lg="12">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Description</label
                                      >
                                      <v-textarea
                                        v-model.trim="asset.preventive_description"
                                        auto-grow
                                        dense
                                        filled
                                        color="cyan"
                                        placeholder="Description"
                                        solo
                                        flat
                                        row-height="15"
                                        v-on:paste="(e) => onPaste(e)"
                                        v-on:keypress="(e) => manageLimit(e)"
                                        :disabled="
                                          equipmentId > 0 ? true : false
                                        "
                                      ></v-textarea>
                                      <v-col md="12" class="text-right"
                                        >{{
                                          asset.preventive_description
                                            ? asset.preventive_description.length
                                            : 0
                                        }}/200</v-col
                                      >
                                    </v-col>
                                  </v-row> -->
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <!--purchase info-->
              <v-tab-item
                :value="'tab-purchase-info'"
                v-if="assetTab == 'tab-purchase-info'"
              >
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    PURCHASE INFORMATION
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="2">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-20"
                                        >Cost</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="cats-equipment"
                                        :items="all_currency"
                                        dense
                                        flat
                                        filled
                                        label="USD"
                                        item-color="cyan"
                                        v-model="asset.equip_cost_currency"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                      >
                                        <!-- 
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_asset_category = true
                                        " -->
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Currency(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="4">
                                      <label
                                        class="pr-2 pl-2 pt-3 font-weight-700 font-size-16 width-100"
                                      ></label>
                                      <v-text-field
                                        :min="0"
                                        type="number"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        placeholder="Cost"
                                        hide-spin-buttons
                                        v-model="asset.purchase_cost"
                                        class="mt-0"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                      ></v-text-field>
                                      <!-- <v-text-field
                                      min="0"
                                      type="number"
                                        filled
                                        label="Cost"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.purchase_cost"
                                       
                                      ></v-text-field> -->
                                    </v-col>

                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Purchase Invoice Number</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Purchase Invoice Number"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="
                                          asset.purchase_invoice_number
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Bought From</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Brought From"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.brought_from"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance Company
                                      </label>
                                      <v-autocomplete
                                        hide-details
                                        id="fina-company-equipment"
                                        :items="financeCompanies"
                                        dense
                                        flat
                                        filled
                                        label="Finance Company"
                                        item-color="cyan"
                                        v-model="asset.finance_company"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="value"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Finance Company(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Finance Start Date"
                                        v-model="asset.finance_start_date"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Finance End Date"
                                        v-model="asset.finance_end_date"
                                        :min-date="asset.finance_start_date"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Company</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="insurance-equipment"
                                        :items="insuranceCompany"
                                        dense
                                        flat
                                        filled
                                        label="Insurance Company"
                                        item-color="cyan"
                                        v-model="asset.insurance_company"
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_insurance_company = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Insurance Company(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Policy</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.insurance_policy"
                                        dense
                                        filled
                                        label="Insurance Policy"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Insurance Start Date"
                                        v-model="asset.insurance_start_date"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Insurance End Date"
                                        v-model="asset.insurance_end_date"
                                        :min-date="asset.insurance_start_date"
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Contract No.</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Contract No."
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.contract_number"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    IMPORT INFORMATION
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                      >
                                        Arrival Date
                                      </label>
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Arrival Date"
                                        v-model="asset.import_arrival_date"
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Permit No.</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.import_permit_no"
                                        dense
                                        filled
                                        label="Permit No."
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Bol No.</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.import_bol_no"
                                        dense
                                        filled
                                        label="BOL No."
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >DO No.</label
                                      >
                                      <v-text-field
                                        v-model.trim="asset.do_no"
                                        dense
                                        filled
                                        label="DO No."
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>

              <!-- cross rent-->
              <v-tab-item
                :value="'tab-cross-rent'"
                v-if="assetTab == 'tab-cross-rent'"
              >
                <v-layout>
                  <v-col
                    class="custom-content-container pt-0"
                    cols="12"
                    :md="12"
                    :sm="12"
                  >
                    <v-layout class="d-block customer-create-new-customer">
                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    CROSS RENT INFORMATION
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="2">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-20"
                                        >Rental Cost</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="cats-equipment"
                                        :items="all_currency"
                                        dense
                                        flat
                                        filled
                                        label="USD"
                                        item-color="cyan"
                                        v-model="
                                          asset.cross_rental_cost_currency
                                        "
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                      >
                                        <!-- 
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_asset_category = true
                                        " -->
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="'No Currency(s) Found.'"
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="4">
                                      <label
                                        class="pr-2 pl-2 pt-3 font-weight-700 font-size-16 width-100"
                                      ></label>
                                      <v-text-field
                                        :min="0"
                                        type="number"
                                        hide-details
                                        dense
                                        solo
                                        flat
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        placeholder="Cost"
                                        hide-spin-buttons
                                        v-model="asset.cross_rent_cost"
                                        class="mt-0"
                                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode==46)"
                                      ></v-text-field>
                                      <!-- <v-text-field
                                      min="0"
                                      type="number"
                                        filled
                                        label="Cost"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="asset.purchase_cost"
                                       
                                      ></v-text-field> -->
                                    </v-col>

                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Rental Invoice Number</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Rental Invoice Number"
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="
                                          asset.cross_rent_invoice_number
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="false">
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance Company
                                      </label>
                                      <v-autocomplete
                                        hide-details
                                        id="fina-company-equipment"
                                        :items="financeCompanies"
                                        dense
                                        flat
                                        filled
                                        label="Finance Company"
                                        item-color="cyan"
                                        v-model="
                                          asset.cross_rent_finance_company
                                        "
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="value"
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Finance Company(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Contract No.</label
                                      >
                                      <v-text-field
                                        filled
                                        label="Contract No."
                                        solo
                                        flat
                                        color="cyan"
                                        dense
                                        v-model.trim="
                                          asset.cross_rent_contract_number
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="false">
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Finance Start Date"
                                        v-model="
                                          asset.cross_rent_finance_start_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Finance End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Finance End Date"
                                        v-model="
                                          asset.cross_rent_finance_end_date
                                        "
                                        :min-date="
                                          asset.cross_rent_finance_start_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                  <v-row v-if="false">
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Company</label
                                      >
                                      <v-autocomplete
                                        hide-details
                                        id="insurance-equipment"
                                        :items="insuranceCompany"
                                        dense
                                        flat
                                        filled
                                        label="Insurance Company"
                                        item-color="cyan"
                                        v-model="
                                          asset.cross_rent_insurance_company
                                        "
                                        color="cyan"
                                        solo
                                        item-text="text"
                                        item-value="text"
                                        append-outer-icon="mdi-cog"
                                        v-on:click:append-outer="
                                          manage_insurance_company = true
                                        "
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title
                                              v-html="
                                                'No Insurance Company(s) Found.'
                                              "
                                            ></v-list-item-title>
                                          </v-list-item>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Policy</label
                                      >
                                      <v-text-field
                                        v-model.trim="
                                          asset.cross_rent_insurance_policy
                                        "
                                        dense
                                        filled
                                        label="Insurance Policy"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row v-if="false">
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Insurance Start Date"
                                        v-model="
                                          asset.cross_rent_insurance_start_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Insurance End Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Insurance End Date"
                                        v-model="
                                          asset.cross_rent_insurance_end_date
                                        "
                                        :min-date="
                                          asset.cross_rent_insurance_start_date
                                        "
                                      ></DatePicker>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col
                          class="iv-custom-field"
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <perfect-scrollbar
                            :options="{ suppressScrollX: true }"
                            class="scroll"
                            style="max-height: 90vh; position: relative"
                          >
                            <v-container fluid>
                              <v-card
                                flat
                                class="custom-grey-border remove-border-radius"
                              >
                                <v-card-title class="headline grey lighten-4">
                                  <h3
                                    class="font-weight-700 custom-headline color-custom-blue"
                                  >
                                    Cross Rent Supplier
                                  </h3>
                                </v-card-title>
                                <v-card-text class="p-6 font-size-16">
                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                        >Supplier Name</label
                                      >
                                      <v-text-field
                                        v-model.trim="
                                          asset.cross_rent_supplier_name
                                        "
                                        dense
                                        filled
                                        label="Supplier Name"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 width-100"
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label for="month" class="ml-2"
                                        >Duration Type</label
                                      >
                                      <v-select
                                        :items="durationTypes"
                                        dense
                                        filled
                                        v-model.trim="
                                          asset.cross_rent_duration_type
                                        "
                                        solo
                                        flat
                                        class="pl-2 pr-2 width-100"
                                        label="Duration Type"
                                        color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        item-color="cyan"
                                        v-on:change="
                                          (e) => UpdateEndDate(e, 'rental')
                                        "
                                      ></v-select>
                                    </v-col>
                                    <v-col lg="6">
                                      <label for="month">Duration</label>
                                      <br />
                                      <v-text-field
                                        id="month2"
                                        type="number"
                                        v-model.trim="asset.cross_rent_month"
                                        dense
                                        filled
                                        label="Month"
                                        solo
                                        flat
                                        color="cyan"
                                        class="pr-2 ml-2 width-100"
                                        :min="1"
                                        oninput="if(this.value < 0) this.value = 0;"
                                        v-on:keyup="
                                          (e) => UpdateEndDate(e, 'rental')
                                        "
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>

                                  <v-row>
                                    <v-col lg="6">
                                      <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required"
                                        >Rental Start Date</label
                                      >
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Rental Start Date"
                                        v-model="asset.cross_rent_start_date"
                                        :rules="[
                                          validateRules.required(
                                            asset.cross_rent_start_date,
                                            'Cross rent start date'
                                          ),
                                        ]"
                                        v-on:change="
                                          (e) => UpdateEndDate(e, 'rental')
                                        "
                                      ></DatePicker>
                                    </v-col>

                                    <v-col lg="6">
                                      <label
                                        class="pr-2 mt-2 pl-1 font-weight-700 font-size-16 width-100"
                                      >
                                        Rental End Date </label
                                      ><br />
                                      <span
                                        class="ml-2 mt-2 black--text"
                                        v-if="asset.cross_rent_end_date"
                                      >
                                        {{
                                          formatDate(asset.cross_rent_end_date)
                                        }}</span
                                      >
                                      <em class="ml-2" v-else>No End Date</em>
                                      <!-- <label
                                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100 required">
                                        Rental End Date
                                      </label>
                                      <DatePicker
                                        solo
                                        flat
                                        :mandatory="false"
                                        :nullable="true"
                                        placeholder="Rental End Date"
                                        v-model="asset.cross_rent_end_date"
                                        :min-date="asset.cross_rent_start_date"
                                        :rules="[
                                          validateRules.required(
                                            asset.cross_rent_end_date,
                                            'Cross rent end date'
                                          ),
                                        ]"
                                      ></DatePicker> -->
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-container>
                          </perfect-scrollbar>
                        </v-col>
                      </v-row>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-tab-item>
            </v-col>
          </v-row>
        </v-tabs-items>
      </v-form>

      <ManageInsuranceCompany
        :b-dialog="manage_insurance_company"
        :brand="insuranceCompany"
        type-cat="insurance_company"
        type-text="Insurance Company"
        Equipment
        v-on:close-dialog="manage_insurance_company = false"
        v-on:get-insurance-company="get_options"
      ></ManageInsuranceCompany>

      <ManageEngineType
        :b-dialog="manage_engine_types"
        :brand="engineTypes"
        type-cat="engine_type"
        type-text="Engine Type"
        Equipment
        v-on:close-dialog="manage_engine_types = false"
        v-on:get-engine-types="get_options"
      ></ManageEngineType>

      <ManageAssetCategory
        :b-dialog="manage_asset_category"
        :brand="assetCats"
        Equipment
        v-on:close-dialog="manage_asset_category = false"
        v-on:get-asset-types="get_options"
      ></ManageAssetCategory>

      <ManageBrand
        :b-dialog="manage_brand"
        :brand="brands"
        Equipment
        v-on:close-dialog="manage_brand = false"
        v-on:get-product-brand="get_options"
      ></ManageBrand>
      <ManageEquipmentLocation
        :dialog="manage_location"
        :equipment="equipment_locations"
        v-on:close-dialog="manage_location = false"
        v-on:get-equipment-location="get_options"
      ></ManageEquipmentLocation>
      <template v-if="manageGroupDialog">
        <ManageCategory
          Equipment
          :c-dialog="manageGroupDialog"
          :category="categoryList"
          v-on:close-dialog="manageGroupDialog = false"
          v-on:get-product-category="get_options"
        ></ManageCategory>
      </template>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST, CLEAR_ERROR, GET } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import { cloneDeep, findIndex, toSafeInteger /*  filter  */ } from "lodash";
import {
  /* SET_MESSAGE, */ SET_ERROR,
} from "@/core/services/store/common.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import CustomerMixin from "@/core/plugins/customer-mixin";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import DatePicker from "@/view/components/DatePicker.vue";
import MomentJS from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageAssetCategory from "@/view/pages/partials/Manage-AssetCategory.vue";
import ManageEngineType from "@/view/pages/partials/ManageTypeWiseCategory.vue";
import ManageInsuranceCompany from "@/view/pages/partials/ManageTypeWiseCategory.vue";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
export default {
  mixins: [CommonMixin, ValidationMixin, CustomerMixin, FileManagerMixin],
  name: "customer-create",
  title: "Create Asset",
  //emits: ['phone-number'],
  data() {
    return {
      allowPurchaseinfo: 0,
      durationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      showPurchaseInfoTab: true,
      unique_barcode: null,
      pageLoading: false,
      leadId: 0,
      imageLoading: false,
      customerList: [],
      formValid: true,
      formLoading: false,
      manage_brand: false,
      manage_asset_category: false,
      manage_engine_types: false,
      manage_insurance_company: false,
      manage_location: false,
      files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      equipment_files: [
        {
          file: null,
          name: null,
          remark: null,
          suffix: null,
          tags: null,
          search: null,
          start_date: null,
          end_date: null,
          reminder_date: null,
        },
      ],
      durationTypeList: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      lead: {},
      minDate: null,
      categoryList: [],
      all_currency: [],
      equipmentId: 0,
      manageGroupDialog: false,
      propertyAddress: {},
      billingAddress: {},
      all_customers: [],
      all_property: [],
      brands: [],
      assetCats: [],
      years: [],
      engineTypes: [],
      insuranceCompany: [],
      products: [],
      supplierList: [],
      equipment_locations: [],
      financeCompanies: [],
      filtered_property: [],
      contactDetail: {},
      contactPerson: [],
      notification: {},
      updatedImg: null,
      machine_types: [],
      machine_models: [],
      asset: {
        cross_rent_month: 1,
        cross_rent_duration_type: "month",
        do_no: null,
        machine_type: 0,
        machine_model: 0,
        selling_price: 0,
        batch_number: null,
        check_maintanance: false,
        preventive_duration_type: "month",
        preventive_start_date: MomentJS().format("YYYY-MM-DD"),
        preventive_end_date: null,
        preventive_duration: 0,
        preventive_description: null,
        asset_type: "personal",
        name: null,
        main_type: "main_customer",
        customer: 0,
        brand: null,
        installation_date: null,
        activation_date: null,
        property: 0,
        product: 0,
        asset_image: null,
        category: null,
        pico_barcode: null,
        serial_no: null,
        location: 0,
        model: null,
        check_warranty: false,
        is_return: false,
        is_purchase_info: false,
        description: null,
        tags: [],
        warranty_start_date: null,
        warranty_end_date: null,
        warranty_duration: 0,
        warranty_duration_type: "month",
        warranty_cost: 0,
        warranty_reminder: [
          {
            type: "after",
            duration: null,
            duration_type: "Day",
            reminder_type: null,
          },
        ],
        company_number: null,
        lp_cert: null,
        owner_ref: null,
        year: null,
        plateform_height: null,
        engine_type: null,
        capacity: null,
        code: null,
        chassis_number: null,
        engine_number: null,
        lta_plate_no: null,
        nea_plate_no: null,
        originial_fork: null,
        noa: null,
        asset_category: null,
        equip_start_date: null,
        equip_end_date: null,
        equip_cost_currency: "SGD",
        purchase_invoice_number: null,
        brought_from: null,
        purchase_cost: 0,
        contract_number: null,
        finance_company: null,
        finance_start_date: null,
        finance_end_date: null,
        insurance_company: null,
        insurance_policy: null,
        insurance_start_date: null,
        insurance_end_date: null,

        import_arrival_date: null,
        import_permit_no: null,
        import_bol_no: null,
        machinery_all_risk: null,
        hour_meter: null,
        breakdown_log: null,

        cross_rental_cost_currency: "SGD",
        cross_rent_cost: 0,
        cross_rent_invoice_number: null,
        cross_rent_finance_company: null,
        cross_rent_contract_number: null,
        cross_rent_finance_start_date: null,
        cross_rent_finance_end_date: null,
        cross_rent_insurance_company: null,
        cross_rent_insurance_policy: null,
        cross_rent_insurance_start_date: null,
        cross_rent_insurance_end_date: null,
        cross_rent_supplier_name: null,
        cross_rent_end_date: null,
        cross_rent_start_date: null,
      },
      warrantyDurationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      wtz_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      wtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      mtx_attachments: Array({
        file: null,
        url: null,
        name: null,
      }),
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "customer",
          status: true,
        },
        {
          title: "EXTENDED INFO",
          icon: "mdi-info",
          key: "extended-info",
          permission: "customer",
          status: true,
        },
        {
          title: "PURCHASE INFO",
          icon: "mdi-info",
          key: "purchase-info",
          permission: "customer",
          status: true,
        },
        {
          title: "CROSS RENT INFO",
          icon: "mdi-info",
          key: "cross-rent",
          permission: "customer",
          status: true,
        },
        {
          title: "Allocate Details",
          icon: "mdi-account-multiple",
          key: "allocate-details",
          permission: "contact-person",
          status: false,
        },
        {
          title: "Warranty Details",
          icon: "mdi-home-map-marker",
          key: "warranty",
          permission: "property",
          status: true,
        },
        // {
        //   title: "Preventive Maintenance",
        //   icon: "mdi-credit-card",
        //   key: "preventive-maintanance",
        //   permission: "property",
        //   status: true,
        // }
        /*   {
          title: "Maintenance Details",
          icon: "mdi-credit-card",
          key: "maintenance",
          permission: "customer",
          status: true,
        }, */
      ],
      assetTab: "tab-overview",
      isCustomerCreated: false,
      isAddressCreated: false,
      options: {},
      steps: {
        details: false,
        "contact-person": true,
        property: true,
        maintenance: true,
        notification: true,
      },
    };
  },
  props: {
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    PhoneTextField,
    TagAutoComplete,
    CreateUpdateTemplate,
    ManageCategory,
    DatePicker,
    ManageBrand,
    ManageAssetCategory,
    ManageEngineType,
    ManageInsuranceCompany,
    Barcode,
    ManageEquipmentLocation,
  },
  watch: {
    assetTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.assetForm.validate();
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },

    /* customerId:{
      deep:true,
      immediate : true,
      handler(param) {
        if(param){
          this.filter_property(param)
        }
      }
    } */
  },
  methods: {
    getUserDetail() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "current-user",
        })
        .then(({ data }) => {
          if (data) {
            _this.allowPurchaseinfo = data?.is_manager;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    UpdateEndDate(value = 0, type = "") {
      if (!value) return false;

      let startDate = "";
      let duration = 1;
      let duration_type = "month";

      if (type == "rental") {
        duration = this.asset.cross_rent_month;
        startDate = this.asset.cross_rent_start_date;
        duration_type = this.asset.cross_rent_duration_type;
      }

      if (startDate) {
        let date = "";
        switch (duration_type) {
          case "day":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = MomentJS(startDate, "YYYY-MM-DD").add(duration, "y");
            break;
        }

        if (date) {
          date = date.format("YYYY-MM-DD");
        }
        if (type == "rental") {
          this.asset.cross_rent_end_date = date;
        }
      }
    },
    filterModels(machine = 0) {
      this.machine_models = [];

      this.machine_types.map((row) => {
        if (row.id == machine) {
          this.machine_models = row.subcategory;
        }
      });
    },
    dateDisable() {
      if (this.equipmentId > 0) {
        return true;
      } else {
        return false;
      }
    },

    updateFileEquipment(indexFile, file) {
      if (file && file.name) {
        this.wtz_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.wtz_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()};`;
      } else {
        this.wtz_attachments[indexFile].name = null;
        this.wtz_attachments[indexFile].suffix = null;
      }
    },
    updateFile(indexFile, file) {
      if (file && file.name) {
        this.wtx_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.wtx_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.wtx_attachments[indexFile].name = null;
        this.wtx_attachments[indexFile].suffix = null;
      }
    },

    updateFileMaintanance(indexFile, file) {
      if (file && file.name) {
        this.mtx_attachments[indexFile].name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.mtx_attachments[indexFile].suffix = `.${file.name
          .split(".")
          .pop()}`;
      } else {
        this.mtx_attachments[indexFile].name = null;
        this.mtx_attachments[indexFile].suffix = null;
      }
    },

    checkType() {
      if (this.asset.main_type == "main_company") {
        this.asset.asset_type = "company";
        // this.ChangeGroupRadio('company');
      } else {
        this.asset.asset_type = "personal";
        // this.ChangeGroupRadio('personal');
      }
    },
    productData(product) {
      if (product > 0) {
        let filtered_product = this.lodash.filter(this.products, {
          id: product,
        });
        this.asset.name = filtered_product[0].name;
        this.asset.brand = filtered_product[0].brand;
        this.asset.serial_no = filtered_product[0].barcode;
        this.asset.category = filtered_product[0].category;
        this.asset.warranty_duration_type = filtered_product[0].duration_time;
        this.asset.warranty_duration = filtered_product[0].warranty_check;
      }
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    /*   new_equipment() {
      this.new_equipment_dailog = true;
      this.filter_property();
    }, */

    get_Equipment() {
      this.$store
        .dispatch(GET, { url: `equipment/${this.equipmentId}` })
        .then(({ data }) => {
          this.unique_barcode = data.barcode;

          (this.updatedImg = data.image ? data.image : null),
            (this.asset.name = data.name ? data.name : null);
          this.asset.main_type = data.main_type ? data.main_type : null;
          this.asset.end_life = data.end_life ? data.end_life : null;
          this.asset.company_number = data.company_number
            ? data.company_number
            : null;
          this.asset.customer =
            data.customer && data.customer.id ? data.customer.id : 0;
          this.asset.property =
            data.property && data.property.id ? data.property.id : 0;
          this.filter_property(this.asset.customer);
          this.asset.brand = data.brand ? data.brand : null;
          this.asset.installation_date = data.installation_date
            ? data.installation_date
            : null;
          this.asset.activation_date = data.activation_date
            ? data.activation_date
            : null;
          this.asset.product = data.product ? data.product : 0;
          this.asset.category = data.category ? data.category : null;
          /*    this.asset.asset_image = data.asset_image ? data.asset_image : null; */
          this.asset.model = data.model ? data.model : null;
          this.asset.location =
            data.location && data.location.id
              ? this.lodash.toString(data.location.id)
              : null;

          this.asset.serial_no = data.serial_no ? data.serial_no : null;
          this.asset.machine_type = data.machine_type ? data.machine_type : 0;
          this.asset.machine_model = data.machine_model
            ? data.machine_model
            : 0;

          if (this.asset.machine_type) {
            this.filterModels(this.asset.machine_type);
          }

          this.asset.check_warranty = data.has_warranty;
          this.changeWarrantyCheck(this.asset.check_warranty);

          this.asset.check_maintanance =
            data.has_preventive_maintanance == "0" ||
            data.has_preventive_maintanance == 0
              ? 0
              : 1;
          this.changeMaintananceCheck(this.asset.check_maintanance);

          //cross rent
          //this.asset.asset_type =
          //data.asset_type == "cross-rent"
          //  ? 1
          // : 0

          //var is_cross_rent = data.type == "cross-rent" ? 1:0;
          this.ChangeGroupRadio(data.type);

          this.asset.is_purchase_info = data.is_purchase_info
            ? data.is_purchase_info
            : 0;
          this.asset.purchased_on = data.purchased_on
            ? data.purchased_on
            : null;
          this.asset.cost_price = data.cost_price ? data.cost_price : 0;
          this.asset.vendor =
            data.supplier && data.supplier.id ? data.supplier.id : 0;

          this.asset.description = data.description ? data.description : null;
          this.asset.tags =
            data.tags /*  && data.tags.length ? data.tags : [] */;
          this.asset.warranty_start_date =
            data.warranty && data.warranty.start_date
              ? data.warranty.start_date
              : null;
          this.asset.warranty_end_date =
            data.warranty && data.warranty.end_date
              ? data.warranty.end_date
              : null;
          this.asset.warranty_duration_type =
            data.warranty && data.warranty.duration_type
              ? data.warranty.duration_type
              : null;
          this.asset.warranty_duration =
            data.warranty && data.warranty.duration
              ? data.warranty.duration
              : 0;
          this.asset.warranty_cost =
            data.warranty && data.warranty.cost ? data.warranty.cost : 0;

          //preventive set details
          this.asset.preventive_start_date =
            data.preventive_maintanance &&
            data.preventive_maintanance.start_date
              ? data.preventive_maintanance.start_date
              : null;
          this.asset.preventive_end_date =
            data.preventive_maintanance && data.preventive_maintanance.end_date
              ? data.preventive_maintanance.end_date
              : null;
          this.asset.preventive_duration_type =
            data.preventive_maintanance &&
            data.preventive_maintanance.duration_type
              ? data.preventive_maintanance.duration_type
              : null;
          this.asset.preventive_duration =
            data.preventive_maintanance && data.preventive_maintanance.duration
              ? data.preventive_maintanance.duration
              : 0;
          this.asset.preventive_cost =
            data.preventive_maintanance && data.preventive_maintanance.cost
              ? data.preventive_maintanance.cost
              : 0;
          this.asset.preventive_description =
            data.preventive_maintanance && data.preventive_maintanance.remark
              ? data.preventive_maintanance.remark
              : null;
          this.asset.batch_number =
            data && data.batch_number ? data.batch_number : null;
          this.asset.selling_price =
            data && data.selling_price ? data.selling_price : null;

          this.asset.asset_image = data.image;

          this.asset.lp_cert =
            data && data.lp_cert && data.lp_cert != "null"
              ? data.lp_cert
              : null;
          this.asset.owner_ref =
            data && data.owner_ref && data.owner_ref != "null"
              ? data.owner_ref
              : null;
          this.asset.year =
            data && data.year && data.year != "null" ? data.year : null;
          this.asset.plateform_height =
            data && data.plateform_height && data.plateform_height != "null"
              ? data.plateform_height
              : null;
          this.asset.engine_type =
            data && data.engine_type && data.engine_type != "null"
              ? data.engine_type
              : null;
          this.asset.capacity =
            data && data.capacity && data.capacity != "null"
              ? data.capacity
              : null;
          this.asset.code =
            data && data.code && data.code != "null" ? data.code : null;
          this.asset.chassis_number =
            data && data.chassis_number && data.chassis_number != "null"
              ? data.chassis_number
              : null;
          this.asset.engine_number =
            data && data.engine_number && data.engine_number != "null"
              ? data.engine_number
              : null;
          this.asset.lta_plate_no =
            data && data.lta_plate_no && data.lta_plate_no != "null"
              ? data.lta_plate_no
              : null;
          this.asset.nea_plate_no =
            data && data.nea_plate_no && data.nea_plate_no != "null"
              ? data.nea_plate_no
              : null;
          this.asset.originial_fork =
            data && data.originial_fork && data.originial_fork != "null"
              ? data.originial_fork
              : null;
          this.asset.noa =
            data && data.noa && data.noa != "null" ? data.noa : null;
          this.asset.asset_category =
            data && data.asset_type && data.asset_type != "null"
              ? data.asset_type
              : null;
          this.asset.equip_start_date =
            data && data.equip_start_date && data.equip_start_date != "null"
              ? data.equip_start_date
              : null;
          this.asset.equip_end_date =
            data && data.equip_end_date && data.equip_end_date != "null"
              ? data.equip_end_date
              : null;
          this.asset.machinery_all_risk =
            data && data.machinery_all_risk && data.machinery_all_risk != "null"
              ? data.machinery_all_risk
              : null;
          this.asset.hour_meter =
            data && data.hour_meter && data.hour_meter != "null"
              ? data.hour_meter
              : null;
          this.asset.breakdown_log =
            data && data.breakdown_log && data.breakdown_log != "null"
              ? data.breakdown_log
              : null;

          this.asset.purchase_cost =
            data && data.equip_cost && data.equip_cost != "null"
              ? data.equip_cost
              : null;
          this.asset.equip_cost_currency =
            data &&
            data.equip_cost_currency &&
            data.equip_cost_currency != "null"
              ? data.equip_cost_currency
              : null;
          this.asset.purchase_invoice_number =
            data &&
            data.purchase_invoice_number &&
            data.purchase_invoice_number != "null"
              ? data.purchase_invoice_number
              : null;
          this.asset.brought_from =
            data && data.brought_from && data.brought_from != "null"
              ? data.brought_from
              : null;
          this.asset.finance_company =
            data && data.finance_company && data.finance_company != "null"
              ? data.finance_company
              : null;
          this.asset.contract_number =
            data && data.contract_number && data.contract_number != "null"
              ? data.contract_number
              : null;
          this.asset.finance_start_date =
            data && data.finance_start_date && data.finance_start_date != "null"
              ? data.finance_start_date
              : null;
          this.asset.finance_end_date =
            data && data.finance_end_date && data.finance_end_date != "null"
              ? data.finance_end_date
              : null;
          this.asset.insurance_company =
            data && data.insurance_company && data.insurance_company != "null"
              ? data.insurance_company
              : null;
          this.asset.insurance_policy =
            data && data.insurance_policy && data.insurance_policy != "null"
              ? data.insurance_policy
              : null;
          this.asset.insurance_start_date =
            data &&
            data.insurance_start_date &&
            data.insurance_start_date != "null"
              ? data.insurance_start_date
              : null;
          this.asset.insurance_end_date =
            data && data.insurance_end_date && data.insurance_end_date != "null"
              ? data.insurance_end_date
              : null;

          //import
          this.asset.import_arrival_date =
            data &&
            data.import_arrival_date &&
            data.import_arrival_date != "null"
              ? data.import_arrival_date
              : null;
          this.asset.import_permit_no =
            data && data.import_permit_no && data.import_permit_no != "null"
              ? data.import_permit_no
              : null;
          this.asset.import_bol_no =
            data && data.import_bol_no && data.import_bol_no != "null"
              ? data.import_bol_no
              : null;
          this.asset.do_no = data.do_no ? data.do_no : null;

          //cross rent
          this.asset.cross_rental_cost_currency =
            data &&
            data.cross_rental_cost_currency &&
            data.cross_rental_cost_currency != "null"
              ? data.cross_rental_cost_currency
              : null;
          this.asset.cross_rent_cost =
            data && data.cross_rent_cost && data.cross_rent_cost != "0"
              ? data.cross_rent_cost
              : null;

          this.asset.cross_rent_invoice_number =
            data &&
            data.cross_rent_invoice_number &&
            data.cross_rent_invoice_number != "null"
              ? data.cross_rent_invoice_number
              : null;
          this.asset.cross_rent_finance_company =
            data &&
            data.cross_rent_finance_company &&
            data.cross_rent_finance_company != "null"
              ? data.cross_rent_finance_company
              : null;
          this.asset.cross_rent_contract_number =
            data &&
            data.cross_rent_contract_number &&
            data.cross_rent_contract_number != "null"
              ? data.cross_rent_contract_number
              : null;
          this.asset.cross_rent_finance_start_date =
            data &&
            data.cross_rent_finance_start_date &&
            data.cross_rent_finance_start_date != "null"
              ? data.cross_rent_finance_start_date
              : null;
          this.asset.cross_rent_finance_end_date =
            data &&
            data.cross_rent_finance_end_date &&
            data.cross_rent_finance_end_date != "null"
              ? data.cross_rent_finance_end_date
              : null;
          this.asset.cross_rent_insurance_company =
            data &&
            data.cross_rent_insurance_company &&
            data.cross_rent_insurance_company != "null"
              ? data.cross_rent_insurance_company
              : null;
          this.asset.cross_rent_insurance_policy =
            data &&
            data.cross_rent_insurance_policy &&
            data.cross_rent_insurance_policy != "null"
              ? data.cross_rent_insurance_policy
              : null;
          this.asset.cross_rent_insurance_start_date =
            data &&
            data.cross_rent_insurance_start_date &&
            data.cross_rent_insurance_start_date != "null"
              ? data.cross_rent_insurance_start_date
              : null;
          this.asset.cross_rent_insurance_end_date =
            data &&
            data.cross_rent_insurance_end_date &&
            data.cross_rent_insurance_end_date != "null"
              ? data.cross_rent_insurance_end_date
              : null;
          this.asset.cross_rent_supplier_name =
            data &&
            data.cross_rent_supplier_name &&
            data.cross_rent_supplier_name != "null"
              ? data.cross_rent_supplier_name
              : null;
          this.asset.cross_rent_start_date =
            data &&
            data.cross_rent_start_date &&
            data.cross_rent_start_date != "null"
              ? data.cross_rent_start_date
              : null;
          this.asset.cross_rent_end_date =
            data &&
            data.cross_rent_end_date &&
            data.cross_rent_end_date != "null"
              ? data.cross_rent_end_date
              : null;

          this.asset.cross_rent_duration_type =
            data &&
            data.cross_rent_duration_type &&
            data.cross_rent_duration_type != "null"
              ? data.cross_rent_duration_type
              : "month";

          this.asset.cross_rent_month =
            data && data.cross_rent_month && data.cross_rent_month != "null"
              ? data.cross_rent_month
              : 1;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    get_options() {
      this.$store
        .dispatch(GET, { url: "equipment/options" })
        .then(({ data }) => {
          this.customerList = data.customers;
          this.supplierList = data.suppliers;
          this.all_property = data.properties;
          if (!this.equipmentId && this.customerId && this.isCustomer) {
            this.filter_property(this.customerId);
          }

          if (data.machine_types) {
            this.machine_types = data?.machine_types;
            //this.filterModels();
          }

          this.brands = data.brands;
          this.assetCats = data.asset_types ? data.asset_types : [];
          this.engineTypes = data.engine_types ? data.engine_types : [];
          this.insuranceCompany = data.insurance_companies
            ? data.insurance_companies
            : [];
          this.financeCompanies = data.finance_companies
            ? data.finance_companies
            : [];

          this.years = data.years ? data.years : [];
          this.all_currency = data.all_currency ? data.all_currency : [];

          this.categoryList = data.category;
          this.products = data.products;
          this.equipment_locations = data.equipment_locations;
          if (!this.equipmentId) {
            this.unique_barcode = data && data.barcode;
          }
          //added here for site loc empty issue on update
          if (this.equipmentId > 0) {
            this.get_Equipment();
          }
          /* this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.asset.customer,
          }); */
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    filter_property(param) {
      this.filtered_property = this.lodash.filter(this.all_property, {
        customer: param,
      });
    },
    manageReminder(index, type) {
      let reminderData = cloneDeep(this.asset.maintenance_reminder);
      let endDate = this.asset.maintenance_end_date;
      if (type == "warranty") {
        reminderData = cloneDeep(this.asset.warranty_reminder);
        endDate = this.asset.warranty_end_date;
      }
      if (
        reminderData[index] &&
        reminderData[index].duration &&
        reminderData[index].duration_type &&
        endDate
      ) {
        endDate = MomentJS(endDate, "YYYY-MM-DD");
        let reminderDate = null;
        if (reminderData[index].type == 0) {
          reminderDate = endDate.subtract(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        } else {
          reminderDate = endDate.add(
            reminderData[index].duration,
            reminderData[index].duration_type
          );
        }
        if (type == "warranty") {
          this.asset.warranty_end_date[index].date =
            reminderDate.format("YYYY-MM-DD");
        }
      }
    },
    addMoreWarranty() {
      this.wtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileWarranty(index) {
      this.wtx_attachments.splice(index, 1);
      if (this.wtx_attachments.length < 1) {
        this.addMoreWarranty();
      }
    },

    addMoreEquipment() {
      this.wtz_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },
    removeFileEquipment(index) {
      this.wtz_attachments.splice(index, 1);
      if (this.wtz_attachments.length < 1) {
        this.addMoreEquipment();
      }
    },
    // addMoreMaintanance() {
    //   this.mtx_attachments.push({
    //     file: null,
    //     url: null,
    //     name: null,
    //   });
    // },
    removeFileMaintanance(index) {
      this.mtx_attachments.splice(index, 1);
      if (this.mtx_attachments.length < 1) {
        this.addMoreMaintanace();
      }
    },
    addMoreMaintanance() {
      this.mtx_attachments.push({
        file: null,
        url: null,
        name: null,
      });
    },

    // removeFileMaintenance(index) {
    //   this.mtx_attachments.splice(index, 1);
    //   if (this.mtx_attachments.length < 1) {
    //     this.addMoreWarranty();
    //   }
    // },
    updateProfileImage(param) {
      const _this = this;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          _this.asset.asset_image =
            data && data.file && data.file.url ? data.file.url : null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    addMoreWarrantyReminder() {
      this.asset.warranty_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    addMoreMaintenanceReminder() {
      this.asset.maintenance_reminder.push({
        type: 0,
        duration: null,
        duration_type: "day",
        reminder_type: null,
      });
    },
    removeWarrantyReminder(index) {
      this.asset.warranty_reminder.splice(index, 1);
      if (this.asset.warranty_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    removeMaintenanceReminder(index) {
      this.asset.maintenance_reminder.splice(index, 1);
      if (this.asset.maintenance_reminder.length < 1) {
        this.addMoreWarrantyReminder();
      }
    },
    updateWarrantyDuration(e, type) {
      let warranty_start_date = e;
      if (type != "start_date") {
        warranty_start_date =
          this.asset && this.asset.warranty_start_date
            ? this.asset.warranty_start_date
            : null;
      }
      if (warranty_start_date) {
        let { warranty_duration_type, warranty_duration } = this.asset;
        let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
        let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
        switch (warranty_duration_type) {
          case "day":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.asset.warranty_end_date = date.format("YYYY-MM-DD");
      }
    },

    updatePreventiveDuration(e, type) {
      let preventive_start_date = e;
      if (type != "start_date") {
        preventive_start_date =
          this.asset && this.asset.preventive_start_date
            ? this.asset.preventive_start_date
            : null;
      }
      if (preventive_start_date) {
        let { preventive_duration_type, preventive_duration } = this.asset;
        let date = MomentJS(preventive_start_date, "YYYY-MM-DD");
        let duration = preventive_duration
          ? toSafeInteger(preventive_duration)
          : 0;
        switch (preventive_duration_type) {
          case "day":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "d"
            );
            break;
          case "month":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "M"
            );
            break;
          case "year":
            date = MomentJS(preventive_start_date, "YYYY-MM-DD").add(
              duration,
              "y"
            );
            break;
        }
        this.asset.preventive_end_date = date.format("YYYY-MM-DD");
      }
    },

    changeWarrantyCheck(data) {
      let warrantyIndex = findIndex(this.tabs, (row) => row.key == "warranty");
      this.tabs[warrantyIndex].status = data;
    },
    changeMaintananceCheck(data) {
      // true or false
      console.log(data);
      //let maintenanceIndex = findIndex(this.tabs, (row) => row.key == "preventive-maintanance");
      //this.tabs[maintenanceIndex].status = data=='0' || data==0 ? false : true;
    },
    ChangeGroupRadio(data = "") {
      if (data == "cross-rent") {
        data = 1;
        this.showPurchaseInfoTab = false;
      } else {
        data = 0;
        this.showPurchaseInfoTab = true;
      }
      let maintenanceIndex = findIndex(
        this.tabs,
        (row) => row.key == "cross-rent"
      );
      this.tabs[maintenanceIndex].status =
        data == "0" || data == 0 ? false : true;

      // this.asset.name =  null;
      // this.asset.product =  0;
      // this.asset.serial_no =  null;
      // (asset.name = null),
      // (asset.serial_no = null),
      // (asset.product = 0)
    },
    manageLimit(e) {
      if (this.asset.description && this.asset.description.length > 199) {
        e.preventDefault();
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.asset.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.asset.description = trimValue;
        e.preventDefault();
      }
    },

    async updateOrCreate() {
      const _this = this;
      const formErrors = _this.validateForm(_this.$refs.assetForm);

      if (formErrors.length) {
        _this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
        return false;
      }

      if (!_this.$refs.assetForm.validate()) {
        return false;
      }

      if (this.asset.check_warranty && !this.asset.warranty_start_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty start date is required")
        );
        return false;
      }
      if (this.asset.check_warranty && !this.asset.warranty_end_date) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Warranty end date is required")
        );
        return false;
      }
      /* let attachment = filter(_this.mtx_attachments, function(row){
        console.log(row);
        return row;
      }); */

      let formData = new FormData();
      console.log(this.asset.asset_type);
      formData.append("type", this.asset.asset_type);
      if (this.asset.main_type == "main_company") {
        formData.append("customer", 0);
        formData.append("property", 0);
      }
      if (this.asset.main_type == "main_customer") {
        formData.append(
          "customer",
          this.asset.customer ? this.asset.customer : this.customerId
        );
        formData.append(
          "property",
          this.asset.property ? this.asset.property : 0
        );
      }
      formData.append("name", this.asset.name ? this.asset.name : 0);

      formData.append(
        "machine_type",
        this.asset.machine_type ? this.asset.machine_type : 0
      );
      formData.append(
        "machine_model",
        this.asset.machine_model ? this.asset.machine_model : 0
      );

      formData.append(
        "serial_no",
        this.asset.serial_no ? this.asset.serial_no : null
      );
      if (this.asset && this.asset.category) {
        formData.append(
          "category",
          this.asset.category ? this.asset.category : null
        );
      }
      if (this.asset && this.asset.installation_date) {
        formData.append(
          "installation_date",
          this.asset.installation_date ? this.asset.installation_date : null
        );
      }
      if (this.asset && this.asset.activation_date) {
        formData.append(
          "activation_date",
          this.asset.activation_date ? this.asset.activation_date : null
        );
      }
      /*  if (this.asset && this.asset.main_type && !this.isCustomer) { */
      formData.append(
        "main_type",
        this.asset.main_type ? this.asset.main_type : null
      );
      /*   } */
      if (this.asset && this.asset.end_life) {
        formData.append(
          "end_life",
          this.asset.end_life ? this.asset.end_life : null
        );
      }
      if (this.asset && this.asset.company_number) {
        formData.append(
          "company_number",
          this.asset.company_number ? this.asset.company_number : null
        );
      }
      if (this.asset && this.asset.cost) {
        formData.append("cost", this.asset.cost ? this.asset.cost : 0);
      }
      if (this.asset && this.asset.location) {
        formData.append(
          "location",
          this.asset.location ? this.asset.location : 0
        );
      }
      if (this.asset && this.asset.product) {
        formData.append("product", this.asset.product ? this.asset.product : 0);
      }
      if (this.asset && this.asset.model) {
        formData.append("model", this.asset.model ? this.asset.model : null);
      }
      if (this.asset && this.asset.asset_image) {
        formData.append("asset_image", this.asset.asset_image);
      }

      if (this.asset && this.asset.brand) {
        formData.append("brand", this.asset.brand ? this.asset.brand : null);
      }
      if (this.asset && this.asset.description) {
        formData.append(
          "description",
          this.asset.description ? this.asset.description : null
        );
      }
      if (this.asset && this.asset.check_warranty) {
        formData.append("check_warranty", this.asset.check_warranty ? 1 : 0);
      }
      if (this.asset && this.asset.is_purchase_info) {
        formData.append(
          "is_purchase_info",
          this.asset.is_purchase_info ? 1 : 0
        );
      }
      if (this.asset && this.asset.is_purchase_info && this.asset.cost_price) {
        formData.append(
          "cost_price",
          this.asset.cost_price ? this.asset.cost_price : 0
        );
      }
      if (
        this.asset &&
        this.asset.is_purchase_info &&
        this.asset.purchased_on
      ) {
        formData.append(
          "purchased_on",
          this.asset.purchased_on ? this.asset.purchased_on : null
        );
      }
      if (this.asset && this.asset.is_purchase_info && this.asset.vendor) {
        formData.append("vendor", this.asset.vendor ? this.asset.vendor : 0);
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration
      ) {
        formData.append(
          "warranty_duration",
          this.asset.warranty_duration ? this.asset.warranty_duration : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_duration_type
      ) {
        formData.append(
          "warranty_duration_type",
          this.asset.warranty_duration_type
            ? this.asset.warranty_duration_type
            : null
        );
      }

      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_start_date
      ) {
        formData.append(
          "warranty_start_date",
          this.asset.warranty_start_date ? this.asset.warranty_start_date : null
        );
      }
      if (
        this.asset &&
        this.asset.check_warranty &&
        this.asset.warranty_end_date
      ) {
        formData.append(
          "warranty_end_date",
          this.asset.warranty_end_date ? this.asset.warranty_end_date : null
        );
      }
      if (this.asset && this.asset.check_warranty && this.asset.warranty_cost) {
        formData.append(
          "warranty_cost",
          this.asset.warranty_cost ? this.asset.warranty_cost : 0
        );
      }

      if (
        this.asset &&
        this.asset.tags &&
        this.asset.tags.length &&
        this.asset.tags.length > 0
      ) {
        for (let i = 0; i < this.asset.tags.length; i++) {
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].type
          ) {
            formData.append(`tags[${i}][type]`, this.asset.tags[i].type);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].color
          ) {
            formData.append(`tags[${i}][color]`, this.asset.tags[i].color);
          }
          if (
            this.asset.tags &&
            this.asset.tags[i] &&
            this.asset.tags[i].text
          ) {
            formData.append(`tags[${i}][text]`, this.asset.tags[i].text);
          }
        }
      }
      if (
        this.asset &&
        this.asset.warranty_reminder &&
        this.asset.warranty_reminder.length &&
        this.asset.warranty_reminder.length > 0
      ) {
        for (let i = 0; i < this.asset.warranty_reminder.length; i++) {
          formData.append(
            `warranty_reminder[${i}][type]`,
            this.asset.warranty_reminder[i].type
              ? this.asset.warranty_reminder[i].type
              : 0
          );

          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration
          ) {
            formData.append(
              `warranty_reminder[${i}][duration]`,
              this.asset.warranty_reminder[i].duration
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].duration_type
          ) {
            formData.append(
              `warranty_reminder[${i}][duration_type]`,
              this.asset.warranty_reminder[i].duration_type
            );
          }
          if (
            this.asset.warranty_reminder &&
            this.asset.warranty_reminder[i] &&
            this.asset.warranty_reminder[i].reminder_type
          ) {
            formData.append(
              `warranty_reminder[${i}][reminder_type]`,
              this.asset.warranty_reminder[i].reminder_type
            );
          }
        }
      }

      //preventive
      if (this.asset && this.asset.check_maintanance) {
        formData.append(
          "has_preventive_maintanance",
          this.asset.check_maintanance ? this.asset.check_maintanance : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_cost
      ) {
        formData.append(
          "preventive_cost",
          this.asset.preventive_cost ? this.asset.preventive_cost : 0
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_description
      ) {
        formData.append(
          "preventive_description",
          this.asset.preventive_description
            ? this.asset.preventive_description
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_end_date
      ) {
        formData.append(
          "preventive_end_date",
          this.asset.preventive_end_date ? this.asset.preventive_end_date : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_start_date
      ) {
        formData.append(
          "preventive_start_date",
          this.asset.preventive_start_date
            ? this.asset.preventive_start_date
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_duration_type
      ) {
        formData.append(
          "preventive_duration_type",
          this.asset.preventive_duration_type
            ? this.asset.preventive_duration_type
            : null
        );
      }
      if (
        this.asset &&
        this.asset.check_maintanance &&
        this.asset.preventive_duration
      ) {
        formData.append(
          "preventive_duration",
          this.asset.preventive_duration ? this.asset.preventive_duration : 0
        );
      }

      formData.append(
        "batch_number",
        this.asset.batch_number ? this.asset.batch_number : null
      );

      //Extended info
      formData.append(
        "lp_cert",
        this.asset.lp_cert ? this.asset.lp_cert : null
      );
      formData.append(
        "owner_ref",
        this.asset.owner_ref ? this.asset.owner_ref : null
      );
      formData.append("year", this.asset.year ? this.asset.year : null);
      formData.append(
        "plateform_height",
        this.asset.plateform_height ? this.asset.plateform_height : null
      );
      formData.append(
        "engine_type",
        this.asset.engine_type ? this.asset.engine_type : null
      );
      formData.append(
        "capacity",
        this.asset.capacity ? this.asset.capacity : null
      );
      formData.append("code", this.asset.code ? this.asset.code : null);
      formData.append(
        "chassis_number",
        this.asset.chassis_number && this.asset.chassis_number != "null"
          ? this.asset.chassis_number
          : null
      );
      formData.append(
        "engine_number",
        this.asset.engine_number && this.asset.engine_number != "null"
          ? this.asset.engine_number
          : null
      );
      formData.append(
        "lta_plate_no",
        this.asset.lta_plate_no ? this.asset.lta_plate_no : null
      );
      formData.append(
        "nea_plate_no",
        this.asset.nea_plate_no ? this.asset.nea_plate_no : null
      );
      formData.append(
        "originial_fork",
        this.asset.originial_fork ? this.asset.originial_fork : null
      );
      formData.append("noa", this.asset.noa ? this.asset.noa : null);
      formData.append(
        "equip_start_date",
        this.asset.equip_start_date ? this.asset.equip_start_date : ""
      );
      formData.append(
        "equip_end_date",
        this.asset.equip_end_date ? this.asset.equip_end_date : ""
      );
      formData.append(
        "machinery_all_risk",
        this.asset.machinery_all_risk ? this.asset.machinery_all_risk : null
      );
      formData.append(
        "hour_meter",
        this.asset.hour_meter ? this.asset.hour_meter : ""
      );
      formData.append(
        "breakdown_log",
        this.asset.breakdown_log ? this.asset.breakdown_log : ""
      );

      formData.append(
        "purchase_cost",
        this.asset.purchase_cost ? this.asset.purchase_cost : null
      );
      formData.append(
        "equip_cost_currency",
        this.asset.equip_cost_currency ? this.asset.equip_cost_currency : null
      );
      formData.append(
        "purchase_invoice_number",
        this.asset.purchase_invoice_number
          ? this.asset.purchase_invoice_number
          : null
      );
      formData.append(
        "brought_from",
        this.asset.brought_from ? this.asset.brought_from : null
      );
      formData.append(
        "finance_company",
        parseInt(this.asset.finance_company)
          ? parseInt(this.asset.finance_company)
          : 0
      );
      formData.append(
        "contract_number",
        this.asset.contract_number ? this.asset.contract_number : null
      );
      formData.append(
        "finance_start_date",
        this.asset.finance_start_date ? this.asset.finance_start_date : ""
      );
      formData.append(
        "finance_end_date",
        this.asset.finance_end_date ? this.asset.finance_end_date : ""
      );
      formData.append(
        "insurance_company",
        this.asset.insurance_company ? this.asset.insurance_company : null
      );
      formData.append(
        "insurance_policy",
        this.asset.insurance_policy ? this.asset.insurance_policy : null
      );
      formData.append(
        "insurance_start_date",
        this.asset.insurance_start_date ? this.asset.insurance_start_date : ""
      );
      formData.append(
        "insurance_end_date",
        this.asset.insurance_end_date ? this.asset.insurance_end_date : ""
      );

      formData.append(
        "import_arrival_date",
        this.asset.import_arrival_date ? this.asset.import_arrival_date : ""
      );
      formData.append(
        "import_permit_no",
        this.asset.import_permit_no ? this.asset.import_permit_no : null
      );
      formData.append(
        "import_bol_no",
        this.asset.import_bol_no ? this.asset.import_bol_no : null
      );
      formData.append("do_no", this.asset.do_no ? this.asset.do_no : null);

      //cross rent info
      formData.append(
        "cross_rental_cost_currency",
        this.asset.cross_rental_cost_currency
          ? this.asset.cross_rental_cost_currency
          : null
      );
      formData.append(
        "cross_rent_cost",
        this.asset.cross_rent_cost ? this.asset.cross_rent_cost : null
      );
      formData.append(
        "cross_rent_invoice_number",
        this.asset.cross_rent_invoice_number
          ? this.asset.cross_rent_invoice_number
          : null
      );
      formData.append(
        "cross_rent_finance_company",
        parseInt(this.asset.cross_rent_finance_company)
          ? parseInt(this.asset.cross_rent_finance_company)
          : 0
      );
      formData.append(
        "cross_rent_contract_number",
        this.asset.cross_rent_contract_number
          ? this.asset.cross_rent_contract_number
          : null
      );
      formData.append(
        "cross_rent_finance_start_date",
        this.asset.cross_rent_finance_start_date
          ? this.asset.cross_rent_finance_start_date
          : ""
      );
      formData.append(
        "cross_rent_finance_end_date",
        this.asset.cross_rent_finance_end_date
          ? this.asset.cross_rent_finance_end_date
          : ""
      );
      formData.append(
        "cross_rent_insurance_company",
        this.asset.cross_rent_insurance_company
          ? this.asset.cross_rent_insurance_company
          : null
      );
      formData.append(
        "cross_rent_insurance_policy",
        this.asset.cross_rent_insurance_policy
          ? this.asset.cross_rent_insurance_policy
          : null
      );
      formData.append(
        "cross_rent_insurance_start_date",
        this.asset.cross_rent_insurance_start_date
          ? this.asset.cross_rent_insurance_start_date
          : ""
      );
      formData.append(
        "cross_rent_insurance_end_date",
        this.asset.cross_rent_insurance_end_date
          ? this.asset.cross_rent_insurance_end_date
          : ""
      );

      formData.append(
        "cross_rent_supplier_name",
        this.asset.cross_rent_supplier_name
          ? this.asset.cross_rent_supplier_name
          : ""
      );
      formData.append(
        "cross_rent_end_date",
        this.asset.cross_rent_end_date &&
          this.asset.cross_rent_end_date != "null"
          ? this.asset.cross_rent_end_date
          : null
      );
      formData.append(
        "cross_rent_start_date",
        this.asset.cross_rent_start_date &&
          this.asset.cross_rent_start_date != "null"
          ? this.asset.cross_rent_start_date
          : null
      );
      formData.append(
        "cross_rent_duration_type",
        this.asset.cross_rent_duration_type &&
          this.asset.cross_rent_duration_type != "null"
          ? this.asset.cross_rent_duration_type
          : "month"
      );
      formData.append(
        "cross_rent_month",
        this.asset.cross_rent_month && this.asset.cross_rent_month != "null"
          ? this.asset.cross_rent_month
          : 1
      );

      formData.append(
        "asset_category",
        this.asset.asset_category ? this.asset.asset_category : null
      );

      formData.append(
        "selling_price",
        this.asset.selling_price ? this.asset.selling_price : 0
      );

      if (
        this.wtx_attachments &&
        this.wtx_attachments.length &&
        this.wtx_attachments.length > 0
      ) {
        for (let i = 0; i < this.wtx_attachments.length; i++) {
          if (
            this.wtx_attachments &&
            this.wtx_attachments[i] &&
            this.wtx_attachments[i].file
          ) {
            formData.append(`file[${i}][file]`, this.wtx_attachments[i].file);
            formData.append(`file[${i}][name]`, this.wtx_attachments[i].name);
          }
        }
      }

      if (
        this.mtx_attachments &&
        this.mtx_attachments.length &&
        this.mtx_attachments.length > 0
      ) {
        for (let i = 0; i < this.mtx_attachments.length; i++) {
          if (
            this.mtx_attachments &&
            this.mtx_attachments[i] &&
            this.mtx_attachments[i].file
          ) {
            formData.append(
              `maintananace_file[${i}][file]`,
              this.mtx_attachments[i].file
            );
            formData.append(
              `maintananace_file[${i}][name]`,
              this.mtx_attachments[i].name
            );
          }
        }
      }

      //equipment files
      if (
        this.wtz_attachments &&
        this.wtz_attachments.length &&
        this.wtz_attachments.length > 0
      ) {
        for (let i = 0; i < this.wtz_attachments.length; i++) {
          if (
            this.wtz_attachments &&
            this.wtz_attachments[i] &&
            this.wtz_attachments[i].file
          ) {
            formData.append(
              `equipment_file[${i}][file]`,
              this.wtz_attachments[i].file
            );
            formData.append(
              `equipment_file[${i}][name]`,
              this.wtz_attachments[i].name
            );
          }
        }
      }

      let requestTYPE = POST;
      let requestURL = "";
      if (this.equipmentId > 0) {
        requestURL = `equipment-new/${this.equipmentId}`;
      } else {
        requestURL = "equipment-new";
      }
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then((data) => {
          this.forcePush = true;
          if (this.isCustomer) {
            _this.$emit("update:warranty", true);
            _this.$emit("close:warranty", true);
          }
          if (!this.isCustomer) {
            this.$router.push(
              this.getDefaultRoute("equipment", {
                params: { id: data.id },
              })
            );
          }
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.get_options();
    this.getUserDetail();
    this.$refs.assetForm.validate();
    if (this.equipmentId > 0) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "equipment", route: "equipment" },
        { title: "Update" },
      ]);
    } else {
      this.changeWarrantyCheck(false);
      this.changeMaintananceCheck(false);
      this.ChangeGroupRadio("personal");
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "equipment", route: "equipment" },
        { title: "Create" },
      ]);
    }
  },
  created() {
    if (!this.isCreate) {
      this.equipmentId = this.lodash.toSafeInteger(this.$route.params.id);
    }
  },
  /*   beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  }, */
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.isCustomerCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      /* if (this.contractId) {
        _this.routePreventDialog = false;
      } else {
        _this.routePreventDialog = true;
      } */
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
  computed: {
    getProfileImage() {
      return this.asset.asset_image ? this.asset.asset_image : null;
      /*  return this.lodash.isEmpty(this.asset.asset_image) === false 
        ? this.asset.asset_image.file.url
        : null; */
    },
  },
};
</script>
